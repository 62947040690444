import React, { useContext } from 'react';
import { ThemeContext } from '../../ContextProvider/ThemeContext';
import styles from './Experiences.module.css';

const Experiences = () => {
	const { newTheme } = useContext(ThemeContext);
	return (
		<div className={styles.experiences}>
			<h1
				style={{ color: `${newTheme.title}` }}
				className={styles.heading}
			>
				Experience
			</h1>
			<div className={styles.borderBottom} />

			<div className={styles.container}>
				<div className={styles.position}>
					Analyst | Civil Engineering{' '}
					<a href='https://www.linkedin.com/company/aidaconsultants/?originalSubdomain=in'>@ AIDA Management</a>
				</div>
				<div className={styles.date}>December 2019 - April 2021</div>
				<fieldset className={styles.responsibilites}>
					<legend>
						<h3> Roles & responsibilites </h3>
					</legend>
					<ul>
						<li>
							My role was to monitor the progress of the site on day to day basis and according to that,
							I did monthly planning, tracking the timely progress and reduce the future risk of the project.
						</li>
						<li>
							Technical Audit, Quality Audit, Safety Audit, etc. Receipt of Drawings from clients,
							Monitoring Placement of orders for the Delivery items/ Plant & Machinery
						</li>
						<li>
							Checked and prepared Running Account (RA) Bills, Daily progress report (DPR).
							Physically visited the site to check the progress.
						</li>
						<li>
							Checked Client Correspondence, Hindrance Register, Extension of Time, etc.
						</li>
						<li>
							Created shop drawings of plan section and elevation.
						</li>
					</ul>
				</fieldset>
			</div>

			<br/>

			<div className={styles.container}>
				<div className={styles.position}>
					Graduate Apprentice | Civil Engineering{' '}
					<a href='https://www.linkedin.com/company/nbcc-india-limited/'>@ NBCC (India) Limited</a>
				</div>
				<div className={styles.date}>December 2018 - December 2019</div>
				<fieldset className={styles.responsibilites}>
					<legend>
						<h3> Roles & responsibilites </h3>
					</legend>
					<ul>
						<li>
							Supported project planning by collecting and documenting
							measurements.
						</li>
						<li>
							Worked alongside senior team members to learn all related job
							tasks and roles.
						</li>
						<li>
							Worked on Reading and Analyzing of structural and
							Architectural drawings
						</li>
						 <li>
							Checked Client Correspondence, Hindrance Register, Extension of Time, etc.
						</li>
						{/*
						<li>
							Created shop drawings of plan section and elevation.
						</li> */}
					</ul>
				</fieldset>
			</div>

		</div>
	);
};

export default Experiences;
